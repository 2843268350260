<template>
    <div class="mx-auto bg-base-100">
      <div class="flex flex-row min-h-screen">
        <div class="flex-none" :class="{'hidden': !isSideBarOpen }">
          <SideBarAdmin/>
        </div>
      
        <div class="flex flex-col w-full">
          <div class="flex-none">
            <NavBarAdmin v-model:sidebar-status="isSideBarOpen"/>
          </div>
          <div class="flex-1 p-10">
              <slot/>
            </div>
          
          <div class="flex-none">
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script setup lang="ts">
  const isSideBarOpen = ref(false)
  
  </script>
  