<template>
  <div class="navbar bg-base-300 justify-between">
    <div class="w-full">
      <button class="btn btn-ghost btn-sm" @click="toggleSidebar">
        <Icon :name="!props.sidebarStatus ? 'ic:baseline-menu' : 'ic:baseline-arrow-back-ios-new'" class="text-2xl"/>
      </button>
      <NuxtLink to="/" class="btn btn-ghost normal-case text-xl">Admin Panel</NuxtLink>
    </div>
    <div class="w-full justify-end">
      <!--logout button-->
      <button class="btn btn-ghost btn-sm" @click="logout">
        <Icon :name="'ic:outline-exit-to-app'" class="text-2xl"/>
      </button>
    </div>
  </div>
</template>
    
<script setup lang="ts">

const props = defineProps({
  sidebarStatus: {
    type: Boolean,
    required: true,
    default: false
  }
})

const emit = defineEmits(['update:sidebarStatus'])

const toggleSidebar = () => {
  //change the value of sidebarStatus to the opposite of what it is now (true/false) and emit the event to the parent component 
  emit('update:sidebarStatus', !props.sidebarStatus)
  //console.log(props.sidebarStatus);
}
const {logout} = useAuth();

</script>