<template>
    <div class="flex flex-col h-full w-64 bg-base-300">
        <!-- Sidebar content goes here -->
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 bg-base-200">
            <div class="flex items-center">
                <div class="w-2 h-2 p-2 mr-2 rounded-full bg-primary-100"></div>
                <h1 class="text-2xl text-primary-500">Benimsaham</h1>
            </div>
        </div>
        <!-- Links with icons from nuxt-icons-->
        <ul class="menu menu-xs bg-base-100 rounded-box space-y-1">
            <li>
                <nuxt-link to="/admin/" class="flex flex-row space-x-2 ">
                    <Icon name="mdi:view-dashboard" size="24px"/>
                    <span>AnaSayfa</span>
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/admin/reservations" class="flex flex-row space-x-2 ">
                    <Icon name="ic:baseline-calendar-month" size="24px"/>
                    <span>Rezervasyonlar</span>
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/admin/subscriptions" class="flex flex-row space-x-2 ">
                    <Icon name="ic:baseline-event-repeat" size="24px"/>
                    <span>Abonelikler</span>
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/admin/videos" class="flex flex-row space-x-2 ">
                    <Icon name="ic:baseline-video-library" size="24px"/>
                    <span>Videolar</span>
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/admin/contact_us" class="flex flex-row space-x-2 ">
                    <Icon name="ic:baseline-contact-phone" size="24px"/>
                    <span>İletişim</span>
                </nuxt-link>
            </li>
            <!--link to services-->
            <li>
                <nuxt-link to="/admin/services" class="flex flex-row space-x-2 ">
                    <Icon name="ic:round-emoji-transportation" size="24px"/>
                    <span>Hizmetler</span>
                </nuxt-link>
            </li>
            <li>
                <nuxt-link to="/admin/posts" class="flex flex-row space-x-2 ">
                    <Icon name="ic:baseline-view-list" size="24px"/>
                    <span>Gönderiler</span>
                </nuxt-link>
            </li>
            <li>
                <details>
                <summary class="space-x-2">
                    <Icon name="ic:baseline-settings" size="24px"/>
                    <span>Ayarlar</span>
                </summary>
                <ul>
                    <li>
                        <nuxt-link to="/admin/settings/area" class="flex flex-row space-x-2 ">
                            <span>Saha</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/admin/settings/site" class="flex flex-row space-x-2 ">
                            <span>Site</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/admin/settings/home_default" class="flex flex-row space-x-2 ">
                            <span>Anasayfa</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/admin/settings/profile" class="flex flex-row space-x-2 ">
                            <span>Profil</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="/admin/settings/music" class="flex flex-row space-x-2 ">
                            <span>Müzikler</span>
                        </nuxt-link>
                    </li>
                    
                </ul>
                </details>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SideBar'
}
</script>

